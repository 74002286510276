import React from "react";
import { useAppSelector } from "../../../api/hooks";
import { selectAuth } from "../../../features/auth/authSlice";
import { useGetQuestionnaireQuery } from "../../../api/services/questionnaireApi";
import styled from "styled-components";

const Recommendations = () => {
  const { user } = useAppSelector(selectAuth);
  const userId = user && user.id;
  const { data, isLoading, isSuccess, isError } = useGetQuestionnaireQuery(
    userId!
  );

  return (
    <Container>
      {isLoading ? (
        <p>Loading ...</p>
      ) : isSuccess && data ? (
        <>
          <InfoWrapper>
            <h2>Your business profile</h2>
            <InfoBox>
              <Info>
                <h4>Business Type</h4>
                <code>{data.data.businessType}</code>
              </Info>

              <Info>
                <h4>Multiple Products</h4>
                <code>{data.data.multipleProducts ? "Yes" : "No"}</code>
              </Info>

              <Info>
                <h4>Brand Voice</h4>
                <code>{data.data.brandVoice}</code>
              </Info>

              <Info>
                <h4>Content Assistance</h4>
                <code>
                  Social Media:{" "}
                  {data.data.contentAssistance.socialMedia ? "Yes" : "No"}
                </code>
                <code>
                  Email Content:{" "}
                  {data.data.contentAssistance.emailContent ? "Yes" : "No"}
                </code>
                <code>
                  Ads: {data.data.contentAssistance.ads ? "Yes" : "No"}
                </code>
              </Info>
            </InfoBox>
          </InfoWrapper>

          <InfoWrapper>
            <h2>Your profile recommendations</h2>
            <InfoBox>
              <h4>Business Type</h4>
              <p>{data.data.businessType}</p>
            </InfoBox>
          </InfoWrapper>
        </>
      ) : isError ? (
        <ErrorText>Error loading data.</ErrorText>
      ) : null}
    </Container>
  );
};

export default Recommendations;

// Styled-components
const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  padding: 1rem 1.5rem;
  gap: 2rem;
`;

const InfoWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.cardBackground};
  padding: 1rem;
  border-radius: 0.5rem;
  width: 100%;
  gap: 1rem;
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  @media (max-width: ${({ theme }) => theme.screens.tab}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ErrorText = styled.p`
  color: ${({ theme }) => theme.colors.error};
  font-size: 16px;
`;
