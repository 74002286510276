import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Button from "../../components/common/Button";

const CheckEmail: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Wrapper>
        <ContentWrapper>
          <Title>Check Your Email</Title>
          <Text>
            A verification link has been sent to your email address. Please
            check your inbox (and spam folder) to verify your email and
            complete your registration.
          </Text>
          <Button onClick={() => navigate("/login")}>Go to Login</Button>
        </ContentWrapper>
      </Wrapper>
    </Container>
  );
};

export default CheckEmail;

const Container = styled.div`
  display: flex;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.backgroundGrey};
  min-height: 100vh;
`;

const Wrapper = styled.div`
  display: flex;
  width: 90%;
  max-width: 400px;
  margin: 2rem auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 4rem);
`;

const ContentWrapper = styled.div`
  width: 100%;
  padding: 2rem;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  text-align: center;
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.textBody};
`;

const Text = styled.p`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.textSecondary};
  margin-bottom: 1.5rem;
`;
