import React, { useState } from "react";
import { styled } from "styled-components";
import { useNavigate } from "react-router-dom";
import { useCreateProductMutation } from "../../api/services/productApi";
import { useAppSelector } from "../../api/hooks";
import { selectAuth } from "../../features/auth/authSlice";
import Input from "../../components/common/Input";
import Button from "../../components/common/Button";
import TextArea from "../../components/common/TextArea";
import Select from "../../components/common/Select";
import { CustomError } from "../../types";
import { uploadImages } from "../../lib/firebase-client";
import FileInput from "../../components/common/FileInput";
import { useGetBusinessQuery } from "../../api/services/businessApi";

const CreateProduct = () => {
  const navigate = useNavigate();
  const { user } = useAppSelector(selectAuth);
  const userId = user && user.id;
  const { data: bData } = useGetBusinessQuery(userId);
  const business = bData && bData.business._id;
  const [createProduct, { isLoading, isSuccess, isError, error }] =
    useCreateProductMutation();

  const [type, setType] = useState("physical-product");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [images, setImages] = useState<File[]>([]);
  const [imagePreviews, setImagePreviews] = useState<string[]>([]);
  const [price, setPrice] = useState<number | undefined>(undefined);
  const [quantity, setQuantity] = useState<number | undefined>(undefined);
  const [categorization, setCategorization] = useState<
    Array<{ sessions: number; price: number }>
  >([]);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const selectedImages = Array.from(event.target.files);
      // Add the new images to the state
      setImages((prevImages) => [...prevImages, ...selectedImages]);

      // Create preview URLs for the images
      const selectedPreviews = selectedImages.map((file) =>
        URL.createObjectURL(file)
      );
      setImagePreviews((prevPreviews) => [
        ...prevPreviews,
        ...selectedPreviews,
      ]);
    }
  };

  const handleCategorizationChange = (
    index: number,
    field: "sessions" | "price",
    value: number
  ) => {
    setCategorization((prev) => {
      const updated = [...prev];
      updated[index] = { ...updated[index], [field]: value };
      return updated;
    });
  };

  const addCategorizationField = () => {
    setCategorization((prev) => [...prev, { sessions: 0, price: 0 }]);
  };

  const removeCategorizationField = (index: number) => {
    setCategorization((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Upload images and get URLs (limit to 5 images)
    const imageUrls = await uploadImages(images.slice(0, 5), "products/images");

    const productData = {
      type,
      name,
      description,
      thumbnails: imageUrls,
      price,
      quantity: type === "physical-product" ? quantity : undefined,
      categorization: type === "service-product" ? categorization : undefined,
      business: business,
      owner: userId,
    };

    try {
      const response = await createProduct(productData).unwrap();
      navigate("/dashboard/products");
    } catch (err) {}
  };

  const getErrorMessage = (err: unknown): string => {
    if (err && (err as CustomError).data) {
      return (err as CustomError).data.error;
    }
    return "Something went wrong!";
  };

  return (
    <Container>
      <Wrapper>
        <h2>Create Your Product</h2>
        <Form onSubmit={handleSubmit}>
          <Select
            name="type"
            value={type}
            onChange={(e) => setType(e.target.value)}
            options={[
              { value: "physical-product", label: "Physical Product" },
              { value: "digital-product", label: "Digital Product" },
              { value: "service-product", label: "Service Product" },
            ]}
          />

          <Input
            type="text"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Product Name"
          />

          <TextArea
            name="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Description"
          />

          <Input
            type="number"
            name="price"
            value={price !== undefined ? price.toString() : ""}
            onChange={(e) => setPrice(Number(e.target.value))}
            placeholder="Price"
          />

          {type === "physical-product" && (
            <Input
              type="number"
              name="quantity"
              value={quantity !== undefined ? quantity.toString() : ""}
              onChange={(e) => setQuantity(Number(e.target.value))}
              placeholder="Quantity"
            />
          )}

          {type === "service-product" && (
            <CategorizationWrapper>
              <h3>Categorization</h3>
              {categorization.map((cat, index) => (
                <div key={index}>
                  <Input
                    type="number"
                    name={`sessions-${index}`}
                    value={cat.sessions.toString()}
                    onChange={(e) =>
                      handleCategorizationChange(
                        index,
                        "sessions",
                        Number(e.target.value)
                      )
                    }
                    placeholder="Sessions"
                  />
                  <Input
                    type="number"
                    name={`price-${index}`}
                    value={cat.price.toString()}
                    onChange={(e) =>
                      handleCategorizationChange(
                        index,
                        "price",
                        Number(e.target.value)
                      )
                    }
                    placeholder="Price"
                  />
                  <Button
                    type="button"
                    onClick={() => removeCategorizationField(index)}
                  >
                    Remove
                  </Button>
                </div>
              ))}
              <Button type="button" onClick={addCategorizationField}>
                Add Categorization
              </Button>
            </CategorizationWrapper>
          )}

          <ImagePreviewWrapper>
            {imagePreviews.map((preview, index) => (
              <ImagePreview key={index}>
                <img src={preview} alt={`preview-${index}`} />
              </ImagePreview>
            ))}
          </ImagePreviewWrapper>

          {images.length < 5 && (
            <FileInput
              onChange={handleImageChange}
              multiple
              accept="image/*"
              label="Upload Product Images"
              required={images.length === 0}
              disabled={isLoading}
            />
          )}

          <Button type="submit" disabled={isLoading}>
            {isLoading ? "Creating..." : "Create Product"}
          </Button>

          {isSuccess && (
            <SuccessMessage>Product created successfully!</SuccessMessage>
          )}
          {isError && <ErrorMessage>{getErrorMessage(error)}</ErrorMessage>}
        </Form>
      </Wrapper>
    </Container>
  );
};

export default CreateProduct;

const Container = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  min-height: 100vh;
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 600px;
  margin-top: 1rem;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const CategorizationWrapper = styled.div`
  margin-top: 1rem;

  div {
    gap: 0.5rem;
  }
`;

const SuccessMessage = styled.p`
  margin-top: 1rem;
  color: ${({ theme }) => theme.colors.green};
`;

const ErrorMessage = styled.p`
  margin-top: 1rem;
  color: ${({ theme }) => theme.colors.red};
`;

const ImagePreviewWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

const ImagePreview = styled.div`
  width: 3rem;
  height: 3rem;
  border: 1px solid ${({ theme }) => theme.colors.green};
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.green};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
