import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";

interface QuestionnaireData {
  userId: string;
  businessType:
    | "physical-product"
    | "service"
    | "digital-product"
    | "combination";
  multipleProducts: boolean;
  contentAssistance: {
    socialMedia: boolean;
    emailContent: boolean;
    ads: boolean;
  };
  brandVoice?: string;
}

// Base URL
const url = process.env.REACT_APP_API_URI as string;

export const questionnaireApi = createApi({
  reducerPath: "questionnaireApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${url}/questionnaires`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    createQuestionnaire: builder.mutation({
      query: (questionnaire) => ({
        url: "create-account-profile",
        method: "POST",
        body: questionnaire,
      }),
    }),

    // Get questionnaire for a user
    getQuestionnaire: builder.query({
      query: (userId) => ({
        url: `${userId}`,
        method: "GET",
      }),
    }),

    //...
  }),
});

export const { useCreateQuestionnaireMutation, useGetQuestionnaireQuery } =
  questionnaireApi;
