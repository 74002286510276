import React from "react";
import { styled } from "styled-components";

type SelectProps = {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  options: { value: string; label: string }[];
  label?: string;
  required?: boolean;
  name?: string;
  isLoading?: boolean; // Include the isLoading prop
};

const Select: React.FC<SelectProps> = ({
  value,
  onChange,
  options,
  label,
  required,
  name,
  isLoading, // Destructure the isLoading prop
}) => {
  return (
    <Question>
      <Label>{label}</Label>
      <StyledSelect
        name={name}
        value={value}
        onChange={onChange}
        required={required}
        disabled={isLoading} // Disable the select when loading
      >
        <option value="">
          {isLoading ? "Loading options..." : "Select Option"}
        </option>
        {!isLoading &&
          options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
      </StyledSelect>
    </Question>
  );
};

export default Select;

const Question = styled.div`
`;

const Label = styled.label`
  font-size: 1.1rem;
  color: ${({ theme }) => theme.colors.textBody};
  display: block;
  //margin-bottom: 0.5rem;
`;

const StyledSelect = styled.select`
  width: 100%;
  padding: 0.6rem;
  font-size: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 6px;
  outline: none;
  transition: border-color 0.3s;

  &:focus {
    border-color: ${({ theme }) => theme.colors.green};
  }
  
  &:disabled {
    background-color: ${({ theme }) => theme.colors.disabledBackground};
    color: ${({ theme }) => theme.colors.disabledText};
    cursor: not-allowed;
  }
`;
