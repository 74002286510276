import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import {
  useEditProductByIdMutation,
  useGetProductByIdQuery,
} from "../../api/services/productApi";
import {
  deleteImageFromFirebase,
  uploadImages,
} from "../../lib/firebase-client";
import { toast } from "react-hot-toast";
import FileInput from "../../components/common/FileInput";
import Button from "../../components/common/Button";

interface Categorization {
  sessions: number;
  price: number;
}

interface FormState {
  name: string;
  description: string;
  thumbnails: string[];
  price: number;
  quantity: number;
  categorization: Categorization[];
  newImages?: any;
}

const EditProduct = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data, isLoading, isError } = useGetProductByIdQuery(id);
  const [editProduct, { isLoading: isEditing, isError: isEditError }] =
    useEditProductByIdMutation();
  const product = data && data.product;

  const [formState, setFormState] = useState<FormState>({
    name: "",
    description: "",
    thumbnails: [],
    price: 0,
    quantity: 0,
    categorization: [],
    newImages: [] as File[],
  });

  const handleCategorizationChange = (
    index: number,
    field: keyof Categorization,
    value: string
  ) => {
    setFormState((prevState) => {
      const updatedCategorization = [...prevState.categorization];
      updatedCategorization[index] = {
        ...updatedCategorization[index],
        [field]:
          field === "price" || field === "sessions" ? Number(value) : value,
      };
      return { ...prevState, categorization: updatedCategorization };
    });
  };

  useEffect(() => {
    if (product) {
      setFormState({
        name: product.name,
        description: product.description,
        thumbnails: product.thumbnails,
        price: product.price,
        quantity: product.quantity || 0,
        categorization: product.categorization || [],
      });
    }
  }, [product]);

  const handleDeleteImage = async (imageUrl: string) => {
    try {
      // Delete the image from Firebase
      await deleteImageFromFirebase(imageUrl);

      // Remove the URL from the `thumbnails` array in the formState object
      const updatedImages = formState.thumbnails.filter(
        (img) => img !== imageUrl
      );

      setFormState({
        ...formState,
        thumbnails: updatedImages,
      });

      // Send the updated image array to the server
      await editProduct({
        id: id!,
        data: {
          thumbnails: updatedImages,
        },
      }).unwrap();

      toast.success("Image deleted successfully");
    } catch (error) {
      console.error("Error deleting image:", error);
      toast.error("Failed to delete image");
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: name === "price" || name === "quantity" ? Number(value) : value,
    }));

    if (e.target instanceof HTMLInputElement && e.target.files) {
      setFormState({
        ...formState,
        newImages: [...formState.newImages, ...Array.from(e.target.files)],
      });
    } else {
      setFormState({ ...formState, [name]: value });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      // Upload images
      let uploadedImageUrls: string[] = [];

      if (formState.newImages && formState.newImages.length > 0) {
        uploadedImageUrls = await uploadImages(formState.newImages, "products");
      }

      // Prepare the updated form data
      const updateData = {
        ...formState,
        thumbnails:
          uploadedImageUrls.length > 0
            ? uploadedImageUrls
            : formState.thumbnails,
      };

      // Update product
      const result = await editProduct({
        id: id!,
        updateData,
      }).unwrap();

      // If update is successful, navigate
      navigate("/dashboard/products");

      toast.success("Product updated successfully");
    } catch (error) {}
  };

  if (isLoading) return <p>Loading product details...</p>;
  if (isError) return <p>Failed to load product details.</p>;

  return (
    <Container>
      <Wrapper>
        <h2>Edit Product</h2>
        <Form onSubmit={handleSubmit}>
          <ItemWrapper>
            <Label>Name</Label>
            <Input
              type="text"
              name="name"
              value={formState.name}
              onChange={handleChange}
              required
            />
          </ItemWrapper>

          <ItemWrapper>
            <Label>Description</Label>
            <TextArea
              name="description"
              value={formState.description}
              onChange={handleChange}
              required
            />
          </ItemWrapper>

          <ItemWrapper>
            <Label>Price</Label>
            <Input
              type="number"
              name="price"
              value={formState.price}
              onChange={handleChange}
              required
            />
          </ItemWrapper>

          {product.type === "physical-product" && (
            <ItemWrapper>
              <Label>Quantity</Label>
              <Input
                type="number"
                name="quantity"
                value={formState.quantity}
                onChange={handleChange}
              />
            </ItemWrapper>
          )}

          {product.type === "service-product" && (
            <ItemWrapper>
              <Label>Categorization</Label>
              {formState.categorization.map((category, index) => (
                <NestedGroup key={index}>
                  <Input
                    type="number"
                    placeholder="Sessions"
                    value={category.sessions}
                    onChange={(e) =>
                      handleCategorizationChange(
                        index,
                        "sessions",
                        e.target.value
                      )
                    }
                  />
                  <Input
                    type="number"
                    placeholder="Price"
                    value={category.price}
                    onChange={(e) =>
                      handleCategorizationChange(index, "price", e.target.value)
                    }
                  />
                </NestedGroup>
              ))}
            </ItemWrapper>
          )}

          <ItemWrapper>
            <Label>Thumbnail URL</Label>
            <Input
              type="text"
              name="thumbnail"
              value={formState.thumbnails[0] || ""}
              onChange={handleChange}
              required
            />
          </ItemWrapper>

          <ItemWrapper>
            <label>Existing Images</label>
            <ImageGrid>
              {formState.thumbnails.map((image: any, index: any) => (
                <ImagePreview key={index}>
                  <img src={image} alt={`Product ${index}`} />
                  <Button onClick={() => handleDeleteImage(image)}>
                    Delete
                  </Button>
                </ImagePreview>
              ))}
            </ImageGrid>
          </ItemWrapper>
          <ItemWrapper>
            <FileInput
              accept="image/*"
              onChange={handleChange}
              multiple
              required={formState.newImages && formState.newImages.length === 0}
              label="Upload New Images"
              disabled={isLoading}
            />
          </ItemWrapper>
          <Button type="submit" disabled={isEditing}>
            {isEditing ? "Saving..." : "Save Changes"}
          </Button>

          {isEditError && <ErrorMessage>Failed to save changes.</ErrorMessage>}
        </Form>
      </Wrapper>
    </Container>
  );
};

export default EditProduct;

const Container = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  min-height: 100vh;
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 600px;
  margin-top: 2rem;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Label = styled.label`
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.colors.textBody};
`;

const ErrorMessage = styled.p`
  margin-top: 1rem;
  color: ${({ theme }) => theme.colors.red};
`;

const ItemWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0.2rem;
`;

const NestedGroup = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const Input = styled.input`
  padding: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 4px;
`;

const TextArea = styled.textarea`
  padding: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 4px;
  resize: none;
`;

const ImageGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

const ImagePreview = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 3rem;
    height: 3rem;
    object-fit: cover;
    margin-bottom: 0.5rem;
  }
`;
