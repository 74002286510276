import React, { useState } from "react";
import useAIContent from "../hooks/useAIContent";

const AIContentGenerator = () => {
  const { generateContent, loading, error, data } = useAIContent({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY || "",
    endpoint: "https://api.openai.com/v1/completions",
  });

  const [prompt, setPrompt] = useState<string>("");
  const [contentType, setContentType] = useState<string>("facebookPost");

  // Max tokens based on content type
  const getMaxTokens = (type: string) => {
    switch (type) {
      case "twitter":
        return 100; // Short content
      case "facebookPost":
        return 300; // Medium-length content
      case "facebookAd":
        return 400; // Longer ad content
      case "linkedinPost":
        return 350; // Professional tone, medium length
      default:
        return 150; // Fallback value
    }
  };

  const handleGenerate = async () => {
    await generateContent(prompt, {
      model: "gpt-4o-mini",
      temperature: 0.7,
      maxTokens: getMaxTokens(contentType),
    });
  };

  return (
    <div>
      <h1>AI Content Generator</h1>

      {/* Content Type Selector */}
      <label htmlFor="contentType">Select Content Type: </label>
      <select
        id="contentType"
        value={contentType}
        onChange={(e) => setContentType(e.target.value)}
      >
        <option value="twitter">Twitter Post</option>
        <option value="facebookPost">Facebook Post</option>
        <option value="facebookAd">Facebook Ad</option>
        <option value="linkedinPost">LinkedIn Post</option>
      </select>

      <br />

      {/* Prompt Input */}
      <textarea
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
        placeholder="Enter your prompt here..."
        rows={5}
        cols={50}
      ></textarea>

      <br />

      {/* Generate Button */}
      <button onClick={handleGenerate} disabled={loading}>
        {loading ? "Generating..." : "Generate Content"}
      </button>

      {/* Error Message */}
      {error && <p style={{ color: "red" }}>{error}</p>}

      {/* Generated Content Display */}
      {data && (
        <div>
          <h2>Generated Content:</h2>
          <p>{data.content}</p>
          <h3>Token Usage:</h3>
          <ul>
            <li>Prompt Tokens: {data.usage.promptTokens}</li>
            <li>Completion Tokens: {data.usage.completionTokens}</li>
            <li>Total Tokens: {data.usage.totalTokens}</li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default AIContentGenerator;
