import React from "react";
import { styled } from "styled-components";

type FileInputProps = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  multiple?: boolean;
  accept?: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
};

const FileInput: React.FC<FileInputProps> = ({
  onChange,
  multiple = false,
  accept,
  label,
  required = false,
  disabled = false,
}) => {
  return (
    <FileInputWrapper>
      {label && <p>{label}</p>}
      <StyledFileInput
        type="file"
        onChange={onChange}
        multiple={multiple}
        accept={accept}
        required={required}
        disabled={disabled}
      />
    </FileInputWrapper>
  );
};

export default FileInput;

const FileInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;



const StyledFileInput = styled.input`
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 6px;
  outline: none;
  transition: border-color 0.3s;

  &:focus {
    border-color: ${({ theme }) => theme.colors.green};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.disabledBackground};
    color: ${({ theme }) => theme.colors.disabledText};
    cursor: not-allowed;
  }
`;
