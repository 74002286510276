import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  roles: string[];
  businessId?: string;
  isActive: boolean;
}

export interface AuthState {
  user: User | null;
  token: string | null;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: AuthState = {
  user: null,
  token: null,
  status: "idle",
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (
      state,
      action: PayloadAction<User & { token: string }>
    ) => {
      const { token, ...user } = action.payload;

      localStorage.setItem("auth", JSON.stringify({ ...user, token }));

      state.user = user;
      state.token = token;
      state.status = "succeeded";
      state.error = null;
    },
    logoutUser: (state) => {
      localStorage.removeItem("auth");

      state.user = null;
      state.token = null;
      state.status = "idle";
      state.error = null;
    },
    setLoading: (state) => {
      state.status = "loading";
      state.error = null;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.status = "failed";
      state.error = action.payload;
    },
  },
});

export const { setUser, logoutUser, setLoading, setError } =
  authSlice.actions;
export default authSlice.reducer;

export const selectAuth = (state: { auth: AuthState }) => state.auth;