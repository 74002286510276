// Checkbox.tsx
import React from "react";
import { styled } from "styled-components";

type CheckboxProps = {
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
};

const Checkbox: React.FC<CheckboxProps> = ({ checked, onChange, label }) => {
  return (
    <Wrapper>
      <StyledCheckbox type="checkbox" checked={checked} onChange={onChange} />
      <LabelText>{label}</LabelText>
    </Wrapper>
  );
};

export default Checkbox;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.8rem;
`;

const StyledCheckbox = styled.input`
  margin-right: 0.5rem;
`;

const LabelText = styled.span`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.textBody};
`;
