// Register.tsx
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useRegisterMutation } from "../api";
import { CustomError } from "../../types";
import Input from "../../components/common/Input";
import Button from "../../components/common/Button";

const Register: React.FC = () => {
  const navigate = useNavigate();
  const [register, { isLoading, error }] = useRegisterMutation();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await register(formData).unwrap();
      navigate("/check-email");
    } catch (err) {
      console.error("Registration failed:", err);
    }
  };

  const errorMessage = (() => {
    if (error) {
      if (
        "data" in error &&
        typeof error.data === "object" &&
        error.data !== null
      ) {
        const customError = error.data as CustomError["data"];
        return customError?.error || "An unknown error occurred";
      }
      if ("message" in error) {
        return error.message;
      }
    }
    return null;
  })();

  return (
    <Container>
      <Wrapper>
        <LogoWrapper>
          <h2>Incubator</h2>
        </LogoWrapper>
        <FormWrapper>
          <Title>Create an Account</Title>
          <Form onSubmit={handleSubmit}>
            <Input
              type="text"
              name="firstName"
              placeholder="First Name"
              value={formData.firstName}
              onChange={handleChange}
            />
            <Input
              type="text"
              name="lastName"
              placeholder="Last Name"
              value={formData.lastName}
              onChange={handleChange}
            />
            <Input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
            />
            <Input
              type="password"
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
            />
            <Button type="submit" disabled={isLoading}>
              {isLoading ? "Registering..." : "Register"}
            </Button>
          </Form>
          {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
          <TextWrapper>
            <p>Already have an account?</p>
            <Links to="/login">Login</Links>
          </TextWrapper>
        </FormWrapper>
        <BottomLinks>
          <Links to="/terms-of-use">Terms of Use</Links>
          <Links to="privacy-policy">Privacy Policy</Links>
        </BottomLinks>
      </Wrapper>
    </Container>
  );
};

export default Register;

const Container = styled.div`
  display: flex;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.backgroundGrey};
  min-height: 100vh;
`;

const Wrapper = styled.div`
  display: flex;
  width: 90%;
  max-width: 400px;
  margin: 1rem auto;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  height: 100vh - 4rem;
`;

const LogoWrapper = styled.div`
  display: flex;
  width: fit-content;
`;

const BottomLinks = styled.div`
  display: flex;
  width: 100%;
  gap: 2rem;
  justify-content: center;
  align-items: center;
`;

const Links = styled(Link)`
  display: flex;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.green};
`;

const FormWrapper = styled.div`
  width: 100%;
  padding: 1rem 2rem;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.textBody};
  margin-bottom: 1.5rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ErrorText = styled.p`
  margin-top: 1rem;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors.error};
`;

const TextWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 1rem;
`;
