import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../api/store";

interface VerifyEmailResponse {
  user: {
    firstName: string;
    lastName: string;
    email: string;
    roles: string[];
    businessId?: string;
    isActive: boolean;
    id: string;
  };
  token: string;
}


//Base URL
const url = process.env.REACT_APP_API_URI as string;

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: `${url}/auth`,
  }),
  endpoints: (builder) => ({
    register: builder.mutation<
      any,
      { firstName: string; lastName: string; email: string; password: string }
    >({
      query: (userData) => ({
        url: "register",
        method: "POST",
        body: userData,
      }),
    }),
    login: builder.mutation<any, { email: string; password: string }>({
      query: (credentials) => ({
        url: "login",
        method: "POST",
        body: credentials,
      }),
    }),

    verifyEmail: builder.mutation<VerifyEmailResponse, string>({
      query: (token) => ({
        url: 'verify-email',
        method: 'GET',
        params: { token },
      }),
    }),
  }),
});

export const { useRegisterMutation, useLoginMutation, useVerifyEmailMutation } = api;
