// src/styles/GlobalStyles.js
import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    font-size: 16px;
  }

  #root {
    margin: 0 auto;
  }

  body {
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: ${({ theme }) => theme.colors.black};
  }

  h2 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    color: ${({ theme }) => theme.colors.black};
  }

  h3 {
    font-size: 1.2rem;
    line-height: 1.4;
    color: ${({ theme }) => theme.colors.textBody};
    font-weight: 600;
  }

  h4 {
    font-size: 1rem;
    line-height: 1.4;
    color: ${({ theme }) => theme.colors.textBody};
    font-weight: bold;
  }

  p {
    font-size: 1rem;
    line-height: 1.4;
    color: ${({ theme }) => theme.colors.textBody};
  }

  p::first-letter {
    text-transform: uppercase;
  }

  small {
    font-size: 0.9rem;
    color: ${({ theme }) => theme.colors.textBody};
  }

  code {
    background-color: #f4f4f4;
    padding: 0.2rem;
    border-radius: 4px;
    font-size: 0.9rem;
   font-family: "Courier New", monospace;
    color: ${({ theme }) => theme.colors.textBody};
    font-weight: 500;
  }

  code::first-letter {
    text-transform: uppercase;
  }

  a {
    color: ${({ theme }) => theme.colors.textBody};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;
