import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useCreateBusinessMutation } from "../../api/services/businessApi";
import { useAppSelector } from "../../api/hooks";
import { AuthState, selectAuth } from "../../features/auth/authSlice";
import { useGetQuestionnaireQuery } from "../../api/services/questionnaireApi";
import { CustomError } from "../../types";
import Input from "../../components/common/Input";
import Button from "../../components/common/Button";
import TextArea from "../../components/common/TextArea";
import Select from "../../components/common/Select";
import { useCountries } from "../../hooks/useCountries";

const CreateBusiness = () => {
  const navigate = useNavigate();
  const { user } = useAppSelector(selectAuth) as AuthState;
  const userId = user && user.id;

  const [createBusiness, { isLoading, isSuccess, isError, error }] =
    useCreateBusinessMutation();
  const { data: qData, isSuccess: qIsSuccess } = useGetQuestionnaireQuery(
    userId,
    {
      skip: !userId,
    }
  );

  const qId = qData && qData.data._id;

  const {
    countries,
    loading: countriesLoading,
    error: cError,
  } = useCountries();

  const [businessName, setBusinessName] = useState("");
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState({
    address: "",
    city: "",
    state: "",
    country: "",
  });
  const [industry, setIndustry] = useState("");

  const handleLocationChange = (
    field: keyof typeof location,
    value: string
  ) => {
    setLocation((prev) => ({ ...prev, [field]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const businessData = {
      businessName,
      description,
      location,
      industry,
      userId: userId,
      questionnaireId: qId,
    };

    try {
      const response = await createBusiness(businessData).unwrap();
      navigate("/dashboard/businesses");
    } catch (err: any) {
      console.error("Error creating business:", err);
    }
  };

  const getErrorMessage = () => {
    const customError = error as CustomError;
    return customError?.data?.error || "Something went wrong!";
  };

  return (
    <Container>
      <Wrapper>
        <Title>Create Your Business</Title>
        <Form onSubmit={handleSubmit}>
          <Input
            type="text"
            name="businessName"
            value={businessName}
            onChange={(e) => setBusinessName(e.target.value)}
            placeholder="Business Name"
          />

          <TextArea
            name="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Description"
          />

          <Input
            type="text"
            name="address"
            value={location.address}
            onChange={(e) => handleLocationChange("address", e.target.value)}
            placeholder="Address"
          />

          <Input
            type="text"
            name="city"
            value={location.city}
            onChange={(e) => handleLocationChange("city", e.target.value)}
            placeholder="City"
          />

          <Input
            type="text"
            name="state"
            value={location.state}
            onChange={(e) => handleLocationChange("state", e.target.value)}
            placeholder="State"
          />

          <Select
            name="country"
            value={location.country}
            onChange={(e) => handleLocationChange("country", e.target.value)}
            options={countries.map((country: any) => ({
              value: country.cca2 || "",
              label: country.name?.common || "Unknown Country",
            }))}
            isLoading={countriesLoading}
          />

          <Input
            type="text"
            name="industry"
            value={industry}
            onChange={(e) => setIndustry(e.target.value)}
            placeholder="Industry"
          />

          <Button type="submit" disabled={isLoading}>
            {isLoading ? "Creating..." : "Create Business"}
          </Button>

          {isSuccess && (
            <SuccessMessage>Business created successfully!</SuccessMessage>
          )}
          {isError && <ErrorMessage>{getErrorMessage()}</ErrorMessage>}
        </Form>
      </Wrapper>
    </Container>
  );
};

export default CreateBusiness;

// Styled components
const Container = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  min-height: 100vh;
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 600px;
  margin-top: 2rem;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  font-size: 1.8rem;
  text-align: center;
  margin-bottom: 1.5rem;
  color: ${({ theme }) => theme.colors.textBody};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const SuccessMessage = styled.p`
  margin-top: 1rem;
  color: ${({ theme }) => theme.colors.green};
`;

const ErrorMessage = styled.p`
  margin-top: 1rem;
  color: ${({ theme }) => theme.colors.red};
`;
