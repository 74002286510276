import React from "react";
import GetBusiness from "./GetBusiness";
import { styled } from "styled-components";

const BusinessOverview = () => {



  return (
    <Container>
      <Wrapper>
        <GetBusiness />
      </Wrapper>
    </Container>
  );
};

export default BusinessOverview;

// Styled Components
const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  margin: 2rem;
  flex-direction: column;
`;
