import React, { useState } from "react";
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
  arrayMove,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { styled } from "styled-components";
import { useParams } from "react-router-dom";
import { useGetProductByIdQuery } from "../../api/services/productApi";

// Initial stages of the sales funnel
const initialFunnelStages = [
  { id: "1", name: "Landing Page" },
  { id: "2", name: "Lead Magnet" },
  { id: "3", name: "Sales Page" },
  { id: "4", name: "Checkout Page" },
  { id: "5", name: "Thank You Page" },
  { id: "6", name: "Upsell/Cross-sell" },
  { id: "7", name: "Post-purchase Follow-up" },
];

const FunnelBuilder: React.FC = () => {
  const { productId } = useParams();
  const [funnelStages, setFunnelStages] = useState(initialFunnelStages);
  const { data } = useGetProductByIdQuery(productId);

  // Configure sensors
  const sensors = useSensors(useSensor(PointerSensor));

  // Handle drag end
  const onDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = funnelStages.findIndex(
        (stage) => stage.id === active.id
      );
      const newIndex = funnelStages.findIndex((stage) => stage.id === over.id);
      const newStages = arrayMove(funnelStages, oldIndex, newIndex);

      setFunnelStages(newStages);
    }
  };

  return (
    <Container>
      <h2>Create Your Sales Funnel</h2>
      <p>
        <strong>Product: </strong>
        {data?.product.name}
      </p>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={onDragEnd}
      >
        <SortableContext
          items={funnelStages.map((stage) => stage.id)}
          strategy={verticalListSortingStrategy}
        >
          <StageList>
            {funnelStages.map((stage) => (
              <SortableStage key={stage.id} id={stage.id} name={stage.name} />
            ))}
          </StageList>
        </SortableContext>
      </DndContext>
    </Container>
  );
};

// Sortable stage component
const SortableStage: React.FC<{ id: string; name: string }> = ({
  id,
  name,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <StageItem ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <StageName>{name}</StageName>
    </StageItem>
  );
};

export default FunnelBuilder;

// Styled components for styling
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
`;

const StageList = styled.div`
  width: 100%;
  max-width: 600px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 1rem;
`;

const StageItem = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.cardBackground};
  margin: 0.5rem 0;
  padding: 1rem;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: grab;
`;

const StageName = styled.p`
  margin: 0;
  font-size: 1.2rem;
`;
