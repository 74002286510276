import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Button from "../../common/Button";
import Input from "../../common/Input";
import Select from "../../common/Select";
import Checkbox from "../../common/Checkbox";
import { useCreateQuestionnaireMutation } from "../../../api/services/questionnaireApi";
import { useAppSelector } from "../../../api/hooks";
import { AuthState, selectAuth } from "../../../features/auth/authSlice";
import { CustomError } from "../../../types";

const Questionnaire = () => {
  const navigate = useNavigate();
  const { user } = useAppSelector(selectAuth) as AuthState;
  const userId = user && user.id;

  const [createQuestionnaire, { isLoading, isSuccess, isError, error }] =
    useCreateQuestionnaireMutation();

  const [businessType, setBusinessType] = useState("");
  const [multipleProducts, setMultipleProducts] = useState(false);
  const [contentAssistance, setContentAssistance] = useState({
    socialMedia: false,
    emailContent: false,
    ads: false,
  });
  const [brandVoice, setBrandVoice] = useState("");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const questionnaireData = {
      userId: userId,
      businessType: businessType as
        | "physical-product"
        | "service"
        | "digital-product"
        | "combination",
      multipleProducts,
      contentAssistance,
      brandVoice,
    };

    try {
      const response = await createQuestionnaire(questionnaireData).unwrap();
      console.log("Questionnaire created successfully:", response);

      navigate("/dashboard/recommendations");
    } catch (err: any) {
      console.error("Error creating questionnaire:", err);
    }
  };

  // Parse the error using the CustomError interface
  const getErrorMessage = () => {
    const customError = error as CustomError;
    return customError?.data?.error || "Something went wrong!";
  };

  return (
    <Container>
      <Wrapper>
        <Title>Business Setup Questionnaire</Title>
        <Form onSubmit={handleSubmit}>
          <Select
            value={businessType}
            onChange={(e) => setBusinessType(e.target.value)}
            label="What kind of business are you running?"
            options={[
              { value: "physical-product", label: "Physical Product Based" },
              { value: "service", label: "Service Based" },
              { value: "digital-product", label: "Digital Product Based" },
              { value: "combination", label: "Combination" },
            ]}
            required
          />

          <Question>
            <Label>Do you have multiple products or services?</Label>
            <Checkbox
              checked={multipleProducts}
              onChange={() => setMultipleProducts((prev) => !prev)}
              label="Yes, I have multiple products."
            />
          </Question>

          <Question>
            <Label>Would you like assistance with the following content?</Label>
            <ContentAssistanceWrapper>
              <Checkbox
                checked={contentAssistance.socialMedia}
                onChange={() =>
                  setContentAssistance((prev) => ({
                    ...prev,
                    socialMedia: !prev.socialMedia,
                  }))
                }
                label="Social Media Content"
              />
              <Checkbox
                checked={contentAssistance.emailContent}
                onChange={() =>
                  setContentAssistance((prev) => ({
                    ...prev,
                    emailContent: !prev.emailContent,
                  }))
                }
                label="Email Content"
              />
              <Checkbox
                checked={contentAssistance.ads}
                onChange={() =>
                  setContentAssistance((prev) => ({
                    ...prev,
                    ads: !prev.ads,
                  }))
                }
                label="Digital Ads"
              />
            </ContentAssistanceWrapper>
          </Question>

          <Question>
            <Label>What is your brand's voice/tone?</Label>
            <Input
              type="text"
              name="brandVoice"
              value={brandVoice}
              onChange={(e) => setBrandVoice(e.target.value)}
              placeholder="Describe your brand's tone (e.g., casual, professional, etc.)"
            />
          </Question>

          <Button type="submit" disabled={isLoading}>
            {isLoading ? "Submitting..." : "Next"}
          </Button>
          {isSuccess && (
            <SuccessMessage>
              Questionnaire submitted successfully!
            </SuccessMessage>
          )}
          {isError && <ErrorMessage>{getErrorMessage()}</ErrorMessage>}
        </Form>
      </Wrapper>
    </Container>
  );
};

export default Questionnaire;

const Container = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  min-height: 100vh;
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 600px;
  margin-top: 2rem;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  font-size: 1.8rem;
  text-align: center;
  margin-bottom: 1.5rem;
  color: ${({ theme }) => theme.colors.textBody};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Question = styled.div`
  margin-bottom: 1.2rem;
`;

const Label = styled.label`
  font-size: 1.1rem;
  color: ${({ theme }) => theme.colors.textBody};
  display: block;
  margin-bottom: 0.5rem;
`;

const ContentAssistanceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
`;

const SuccessMessage = styled.p`
  margin-top: 1rem;
  color: ${({ theme }) => theme.colors.green};
`;

const ErrorMessage = styled.p`
  margin-top: 1rem;
  color: ${({ theme }) => theme.colors.red};
`;
