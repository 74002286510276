import React, { useState, useEffect } from "react";
import {
  useEditBusinessMutation,
  useGetBusinessQuery,
} from "../../api/services/businessApi";
import { useAppSelector } from "../../api/hooks";
import { selectAuth } from "../auth/authSlice";
import { useParams, useNavigate } from "react-router-dom";
import { CustomError } from "../../types";
import Input from "../../components/common/Input";
import TextArea from "../../components/common/TextArea";
import Button from "../../components/common/Button";
import Checkbox from "../../components/common/Checkbox";
import Select from "../../components/common/Select";
import { styled } from "styled-components";
import { useCountries } from "../../hooks/useCountries";

const EditBusiness = () => {
  const { user } = useAppSelector(selectAuth);
  const userId = user && user.id;
  const navigate = useNavigate();
  const {
    data: businessData,
    error: businessError,
    isLoading: businessIsLoading,
  } = useGetBusinessQuery(userId, {
    skip: !userId,
  });

  const businessId = businessData && businessData.business._id;

  const { countries, loading, error } = useCountries();

  const [businessName, setBusinessName] = useState("");
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState({
    address: "",
    city: "",
    state: "",
    country: "",
  });
  const [industry, setIndustry] = useState("");
  const [businessType, setBusinessType] = useState("");
  const [multipleProducts, setMultipleProducts] = useState(false);
  const [brandVoice, setBrandVoice] = useState("");
  const [contentAssistance, setContentAssistance] = useState({
    socialMedia: false,
    emailContent: false,
    ads: false,
  });

  const [editBusiness, { isLoading, error: editBusinessError }] =
    useEditBusinessMutation();

  useEffect(() => {
    if (businessData) {
      const { business } = businessData;
      setBusinessName(business.businessName);
      setDescription(business.description);
      setLocation(business.location);
      setIndustry(business.industry);
      setBusinessType(business.businessType);
      setMultipleProducts(business.multipleProducts);
      setBrandVoice(business.brandVoice);
      setContentAssistance(business.contentAssistance);
    }
  }, [businessData]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const updatedBusinessData = {
      businessName,
      description,
      location,
      industry,
      businessType,
      multipleProducts,
      brandVoice,
      contentAssistance,
      businessId,
    };

    try {
      // Pass businessId and updatedBusinessData in the correct structure
      const response = await editBusiness({
        businessId: businessId,
        businessData: updatedBusinessData,
      }).unwrap();

      navigate(-1);
    } catch (err: any) {
      console.error("Error updating business:", err);
    }
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setContentAssistance((prev) => ({ ...prev, [name]: checked }));
  };

  const getErrorMessage = () => {
    const customError = editBusinessError as CustomError;
    return customError?.data?.error || "Something went wrong!";
  };

  if (businessIsLoading || loading) {
    return <p>Loading...</p>;
  }

  return (
    <Container>
      <Wrapper>
        <h2>Edit Business</h2>
        <Form onSubmit={handleSubmit}>
          <ItemWrapper>
            <Label>Business Name</Label>
            <Input
              type="text"
              name="businessName"
              value={businessName}
              onChange={(e) => setBusinessName(e.target.value)}
              placeholder="Business Name"
            />
          </ItemWrapper>

          <ItemWrapper>
            <Label>Description</Label>
            <TextArea
              name="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Description"
            />
          </ItemWrapper>

          <ItemWrapper>
            <Label>Address</Label>
            <Input
              type="text"
              name="address"
              value={location.address}
              onChange={(e) =>
                setLocation({ ...location, address: e.target.value })
              }
              placeholder="Address"
            />
          </ItemWrapper>
          <Item>
            <ItemWrapper>
              <Label>City</Label>
              <Input
                type="text"
                name="city"
                value={location.city}
                onChange={(e) =>
                  setLocation({ ...location, city: e.target.value })
                }
                placeholder="City"
              />
            </ItemWrapper>

            <ItemWrapper>
              <Label>State</Label>
              <Input
                type="text"
                name="state"
                value={location.state}
                onChange={(e) =>
                  setLocation({ ...location, state: e.target.value })
                }
                placeholder="State"
              />
            </ItemWrapper>

            <ItemWrapper>
              <Label>Country</Label>
              <Select
                name="country"
                value={location.country}
                onChange={(e) =>
                  setLocation({ ...location, country: e.target.value })
                }
                options={countries.map((country: any) => ({
                  value: country.cca2 || "",
                  label: country.name?.common || "Unknown Country",
                }))}
                isLoading={loading}
              />
            </ItemWrapper>
          </Item>

          <Item>
            <ItemWrapper>
              <Label>Industry</Label>
              <Input
                type="text"
                name="industry"
                value={industry}
                onChange={(e) => setIndustry(e.target.value)}
                placeholder="Industry"
              />
            </ItemWrapper>

            <ItemWrapper>
              <Label>Country</Label>
              <Select
                value={businessType}
                onChange={(e) => setBusinessType(e.target.value)}
                options={[
                  {
                    value: "physical-product",
                    label: "Physical Product Based",
                  },
                  { value: "service", label: "Service Based" },
                  { value: "digital-product", label: "Digital Product Based" },
                  { value: "combination", label: "Combination" },
                ]}
                required
              />
            </ItemWrapper>
          </Item>

          <ItemWrapper>
            <Label>Multiple Products</Label>
            <Checkbox
              checked={multipleProducts}
              onChange={(e) => setMultipleProducts(e.target.checked)}
              label="Enable Multiple Products"
            />
          </ItemWrapper>

          <ItemWrapper>
            <Label>Brand Voice</Label>
            <TextArea
              name="brandVoice"
              value={brandVoice}
              onChange={(e) => setBrandVoice(e.target.value)}
              placeholder="Brand Voice"
            />
          </ItemWrapper>

          <ItemWrapper>
            <p>Content Assistance</p>
            <Item>
              <Checkbox
                checked={contentAssistance.socialMedia}
                onChange={handleCheckboxChange}
                label="Social Media"
              />
              <Checkbox
                checked={contentAssistance.emailContent}
                onChange={handleCheckboxChange}
                label="Email Content"
              />
              <Checkbox
                checked={contentAssistance.ads}
                onChange={handleCheckboxChange}
                label="Ads"
              />
            </Item>
          </ItemWrapper>

          <Button type="submit" disabled={isLoading}>
            {isLoading ? "Updating..." : "Update Business"}
          </Button>

          {error && <ErrorMessage>{getErrorMessage()}</ErrorMessage>}
        </Form>
      </Wrapper>
    </Container>
  );
};

export default EditBusiness;

const Container = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  min-height: 100vh;
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 600px;
  margin-top: 2rem;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Label = styled.label`
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.colors.textBody};
`;

const ErrorMessage = styled.p`
  margin-top: 1rem;
  color: ${({ theme }) => theme.colors.red};
`;

const ItemWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0.2rem;
`;

const Item = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
`;
