import React from "react";
import { useGetBusinessQuery } from "../../api/services/businessApi";
import { useAppSelector } from "../../api/hooks";
import { selectAuth } from "../auth/authSlice";
import { CustomError } from "../../types";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Button from "../../components/common/Button";

const GetBusiness = () => {
  const { user } = useAppSelector(selectAuth);
  const userId = user && user.id;
  const { data, error, isLoading } = useGetBusinessQuery(userId, {
    skip: !userId,
  });
  const navigate = useNavigate();

  const handleNavigateToCreate = () => {
    navigate("create-business");
  };

  if (isLoading) return <p>Loading...</p>;

  if (error && !(data && !data.business)) {
    // Display error only for true errors, not "no business found."
    const customError = error as CustomError;
    return (
      <ErrorMessage>{`Error fetching business: ${customError?.data?.error}`}</ErrorMessage>
    );
  }

  const business = data?.business;

  if (!business) {
    // Handle the case where no business exists
    return (
      <Container>
        <Wrap>
          <p>No business found. Create your first business!</p>
          <BtnWrap>
            <Button onClick={handleNavigateToCreate}>
              Create Your Business
            </Button>
          </BtnWrap>
        </Wrap>
      </Container>
    );
  }

  const {
    location,
    contentAssistance,
    businessName,
    description,
    industry,
    businessType,
    multipleProducts,
    brandVoice,
    _id: businessId,
  } = business;

  return (
    <Container>
      <Wrapper>
        <h2>{businessName}</h2>
        <Description>{description}</Description>
        <Text>
          <strong>Industry:</strong> {industry}
        </Text>
        <Text>
          <strong>Business Type:</strong> {businessType}
        </Text>
        <Text>
          <strong>Multiple Products:</strong> {multipleProducts ? "Yes" : "No"}
        </Text>
        <Text>
          <strong>Brand Voice:</strong> {brandVoice}
        </Text>

        <SectionTitle>Location</SectionTitle>
        <Text>
          <strong>Address:</strong> {location?.address}
        </Text>
        <Text>
          <strong>City:</strong> {location?.city}
        </Text>
        <Text>
          <strong>State:</strong> {location?.state}
        </Text>
        <Text>
          <strong>Country:</strong> {location?.country}
        </Text>

        <SectionTitle>Content Assistance</SectionTitle>
        <Text>
          <strong>Social Media:</strong>{" "}
          {contentAssistance?.socialMedia ? "Yes" : "No"}
        </Text>
        <Text>
          <strong>Email Content:</strong>{" "}
          {contentAssistance?.emailContent ? "Yes" : "No"}
        </Text>
        <Text>
          <strong>Ads:</strong> {contentAssistance?.ads ? "Yes" : "No"}
        </Text>

        <Button onClick={() => navigate(`${businessId}/edit-business`)}>
          Edit Business
        </Button>
      </Wrapper>
    </Container>
  );
};

export default GetBusiness;

const Container = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  min-height: 100vh;
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 2rem;
`;

const Wrap = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: fit-content;
`;

const BtnWrap = styled.div`
  display: flex;
  width: fit-content;
  margin: 1rem auto;
`;

const Description = styled.p`
  margin-bottom: 1rem;
`;

const Text = styled.p`
  margin-bottom: 0.5rem;
`;

const SectionTitle = styled.h3`
  color: ${({ theme }) => theme.colors.textTitle};
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
`;

const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.red};
  font-size: 1.1rem;
  margin-top: 1rem;
  text-align: center;
`;
