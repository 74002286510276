import React from "react";
import { useGetAllProductsQuery } from "../../api/services/productApi";
import { useAppSelector } from "../../api/hooks";
import { CustomError } from "../../types";
import { styled } from "styled-components";
import { useNavigate } from "react-router-dom";
import Button from "../../components/common/Button";
import { selectAuth } from "../auth/authSlice";
import { useGetBusinessQuery } from "../../api/services/businessApi";

const GetAllProducts = () => {
  const navigate = useNavigate();
  const { user } = useAppSelector(selectAuth);
  const userId = user && user.id;
  const { data: bData } = useGetBusinessQuery(userId);
  const business = bData && bData.business._id;
  const { data, error, isLoading } = useGetAllProductsQuery({ business });

  const handleNavigateToCreateProduct = () => {
    navigate("create-product");
  };

  if (isLoading)
    return (
      <Container>
        <Wrap>
          <p>Loading...</p>
        </Wrap>
      </Container>
    );

  if (error) {
    const customError = error as CustomError;
    return (
      <ErrorMessage>{`Error fetching products: ${customError?.data?.error}`}</ErrorMessage>
    );
  }

  const products = data?.products;

  if (!products || products.length === 0) {
    return (
      <Container>
        <Wrap>
          <p>No products found. Create your first product!</p>
          <BtnWrap>
            <Button onClick={handleNavigateToCreateProduct}>
              Create a Product
            </Button>
          </BtnWrap>
        </Wrap>
      </Container>
    );
  }

  return (
    <Container>
      <Wrapper>
        <TitleWrapper>
          <h2>Your Products</h2>
          <Button onClick={handleNavigateToCreateProduct}>Add a Product</Button>
        </TitleWrapper>

        {products.map((product: any) => (
          <ProductCard key={product._id}>
            <h3>{product.name}</h3>
            <Description>{product.description}</Description>
            <Text>
              <strong>Type:</strong> {product.type}
            </Text>
            <Text>
              <strong>Price:</strong> ${product.price}
            </Text>
            {product.type === "physical-product" && (
              <Text>
                <strong>Quantity:</strong> {product.quantity}
              </Text>
            )}
            {product.type === "service-product" && product.categorization && (
              <div>
                <strong>Sessions:</strong>
                <ul>
                  {product.categorization.map((cat: any, index: any) => (
                    <li key={index}>
                      {cat.sessions} sessions - ${cat.price}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <Button onClick={() => navigate(`${product._id}/edit-product`)}>
              Edit Product
            </Button>
          </ProductCard>
        ))}
      </Wrapper>
    </Container>
  );
};

export default GetAllProducts;

const Container = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  min-height: 100vh;
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 1rem 2rem;
`;

const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const Wrap = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: fit-content;
  margin-top: 1.5rem;
`;

const BtnWrap = styled.div`
  display: flex;
  width: fit-content;
  margin: 1rem auto;
`;

const Description = styled.p`
  margin-bottom: 1rem;
`;

const Text = styled.p`
  margin-bottom: 0.5rem;
`;

const ProductCard = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 1rem;
`;

const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.erro};
  margin-top: 1rem;
  text-align: center;
`;
