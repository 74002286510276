// src/styles/colors.ts
export const colors = {
  black: "#0d0d0d",
  textBody: "#333",
  backgroundGrey: "#F5F5F5",
  deeperBackgroundGrey: "#ECECF1",
  white: "#FFFFFF",
  green: "#10a37f",
  hoverGreen: "#139373",
  disabledGrey: "#d6d6d6",
  error: "#ff4d4f",
  border: "#c2c8d0",
  cardBackground:"#FAFAFA",
};
