import React, { useEffect, useRef } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { routes } from "./config/routes";
import { useAppDispatch } from "./api/hooks";
import { setUser, logoutUser } from "./features/auth/authSlice";

const AppWrapper = () => {
  const dispatch = useAppDispatch();
  const inactivityTimeout = useRef<NodeJS.Timeout | null>(null);

  const user = JSON.parse(localStorage.getItem("auth") || "{}");

  useEffect(() => {
    dispatch(setUser(user));

    // Function to reset the inactivity timer
    const resetInactivityTimeout = () => {
      if (inactivityTimeout.current) {
        clearTimeout(inactivityTimeout.current);
      }
      inactivityTimeout.current = setTimeout(() => {
        handleLogout();
      }, 60 * 60 * 1000); // 1 hour timeout
    };

    // Function to handle user logout
    const handleLogout = () => {
      const lastVisitedPage = window.location.pathname;
      localStorage.setItem("lastVisitedPage", lastVisitedPage);
      dispatch(logoutUser());
      localStorage.removeItem("auth");
      window.location.href = "/login"; // Redirect to login
    };

    // Add event listeners for user activity
    const events = ["mousemove", "click", "keydown", "scroll", "touchstart"];
    events.forEach((event) =>
      window.addEventListener(event, resetInactivityTimeout)
    );

    // Set initial timeout
    resetInactivityTimeout();

    // Clean up event listeners and timeout on component unmount
    return () => {
      if (inactivityTimeout.current) {
        clearTimeout(inactivityTimeout.current);
      }
      events.forEach((event) =>
        window.removeEventListener(event, resetInactivityTimeout)
      );
    };
  }, [dispatch]);

  return null;
};

const App = () => {
  return (
    <BrowserRouter>
      <AppWrapper />
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element}>
            {route.children &&
              route.children.map((childRoute, childIndex) => (
                <Route
                  key={childIndex}
                  path={childRoute.path}
                  element={childRoute.element}
                />
              ))}
          </Route>
        ))}
      </Routes>
    </BrowserRouter>
  );
};

export default App;
