import React from "react";
import { useAppSelector } from "../../api/hooks";
import { selectAuth } from "../auth/authSlice";
import { useGetAllProductsQuery } from "../../api/services/productApi";
import { useGetBusinessQuery } from "../../api/services/businessApi";
import { styled } from "styled-components";
import Button from "../../components/common/Button";
import { useNavigate } from "react-router-dom";
import { CustomError } from "../../types";
import SmallButton from "../../components/common/SmallButton";

const ChooseProductForFunnel = () => {
  const navigate = useNavigate();
  const { user } = useAppSelector(selectAuth);
  const userId = user && user.id;
  const { data: bData } = useGetBusinessQuery(userId);
  const business = bData && bData.business._id;
  const { data, error, isLoading } = useGetAllProductsQuery({ business });
  const products = data && data.products;

  const handleNavigateToCreateProduct = () => {
    navigate("/dashboard/products/create-product");
  };

  if (isLoading)
    return (
      <Container>
        <Wrap>
          <p>Loading...</p>
        </Wrap>
      </Container>
    );

  if (error) {
    const customError = error as CustomError;
    return (
      <ErrorMessage>{`Error fetching products: ${customError?.data?.error}`}</ErrorMessage>
    );
  }

  if (!products || products.length === 0) {
    return (
      <Container>
        <Wrap>
          <p>No products found. Create your first product!</p>
          <BtnWrap>
            <Button onClick={handleNavigateToCreateProduct}>
              Create a Product
            </Button>
          </BtnWrap>
        </Wrap>
      </Container>
    );
  }

  return (
    <Container>
      <Wrapper>
        <TitleWrapper>
          <h2>Select product</h2>
        </TitleWrapper>
        <ProductWrapper>
          {products.map((product: any) => (
            <ProductCard key={product._id}>
              <h3>{product.name}</h3>

              <SmallButton
                onClick={() =>
                  navigate(`/dashboard/funnels/products/${product._id}/create`)
                }
              >
                Select
              </SmallButton>
            </ProductCard>
          ))}
        </ProductWrapper>
      </Wrapper>
    </Container>
  );
};

export default ChooseProductForFunnel;

const Container = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  min-height: 100vh;
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 1rem 2rem;
`;

const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const Wrap = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: fit-content;
  margin-top: 1.5rem;
`;

const BtnWrap = styled.div`
  display: flex;
  width: fit-content;
  margin: 1rem auto;
`;

const ProductWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: ${({theme}) => theme.screens.tab}) {
    flex-direction: column;
  }
`;

const ProductCard = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.border};
  background-color: ${({ theme }) => theme.colors.backgroundGrey};
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 1rem;
  display: flex;
  width: 30%;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: ${({theme}) => theme.screens.tab}) {
    width: 100%;
  }
`;

const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.erro};
  margin-top: 1rem;
  text-align: center;
`;
