import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { api } from "../features/api";
import { questionnaireApi } from "./services/questionnaireApi";
import { businessApi } from "./services/businessApi";
import { productApi } from "./services/productApi";
import authReducer from "../features/auth/authSlice";

// Initialize state from localStorage with proper type safety
const preloadedAuth = JSON.parse(localStorage.getItem("auth") || "null");

export const store = configureStore({
  reducer: {
    auth: authReducer,
    [api.reducerPath]: api.reducer,
    [questionnaireApi.reducerPath]: questionnaireApi.reducer,
    [businessApi.reducerPath]: businessApi.reducer,
    [productApi.reducerPath]: productApi.reducer,
  },
  preloadedState: {
    auth: preloadedAuth
      ? { ...preloadedAuth, status: "succeeded", error: null }
      : undefined,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(api.middleware)
      .concat(questionnaireApi.middleware)
      .concat(businessApi.middleware)
      .concat(productApi.middleware),
});

// Set up listeners for cache refetching and background tasks
setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
