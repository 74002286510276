import React, { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { HiCubeTransparent } from "react-icons/hi2";
import { IoPersonOutline } from "react-icons/io5";
import { colors } from "../styles/colors";
import { useAppDispatch, useAppSelector } from "../api/hooks";
import { logoutUser, selectAuth } from "../features/auth/authSlice";

interface DashboardLayoutProps {
  children: React.ReactNode;
}

const navContent = [
  { link: "home", content: "Dashboard" },
  { link: "businesses", content: "Businesses" },
  { link: "products", content: "Products" },
  { link: "funnels", content: "Funnels" },
  { link: "landing-pages", content: "Landing Pages" },
  { link: "leads", content: "Leads" },
  { link: "emails", content: "Emails" },
  { link: "subscriptions", content: "Subscriptions" },
  { link: "ai-assistant", content: "AI Assistant" },
];

const DashboardLayout: React.FC<DashboardLayoutProps> = ({ children }) => {
const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { user, token } = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const dropdownRef = useRef<HTMLDivElement>(null); 

  const toggleMobileSidebar = () => {
    setIsMobileSidebarOpen((prevState) => !prevState);
  };

  const closeMobileSidebar = () => {
    setIsMobileSidebarOpen(false);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  const handleLogout = () => {
    dispatch(logoutUser());
    navigate("/login");
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false); // Close dropdown
      }
    };

    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isDropdownOpen]);

  return (
    <DashboardContainer>
      <TopBar>
        <Logo>
          <h2>Incubator</h2>
        </Logo>
        <TopBarMenu>
          <UpgradeWrapper to="/dashboard/upgrade">
            <UpgradeIcon>
              <HiCubeTransparent />
            </UpgradeIcon>
            <p>Upgrade Plan</p>
          </UpgradeWrapper>
          <ProfileButton onClick={toggleDropdown}>
            <ProfileIcon>
              <IoPersonOutline color={colors.white} />
            </ProfileIcon>
            <p>{user ? `${user?.firstName} ${user?.lastName}` : null}</p>
          </ProfileButton>
          {isDropdownOpen && (
            <DropdownMenu ref={dropdownRef}>
              <DropdownItem>Settings</DropdownItem>
              <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
            </DropdownMenu>
          )}
        </TopBarMenu>
      </TopBar>

      {/* Navbar for smaller screens */}
      <MobileNavbar>
        <Logo>
          <h2>Incubator</h2>
        </Logo>
        <HamburgerMenu onClick={toggleMobileSidebar}>&#9776;</HamburgerMenu>
      </MobileNavbar>

      {/* Mobile Sidebar for smaller screens */}
      <MobileSidebar isOpen={isMobileSidebarOpen}>
        <NavMenu>
          {navContent.map((item) => (
            <NavItem
              to={item.link}
              onClick={closeMobileSidebar}
              key={item.link}
            >
              {item.content}
            </NavItem>
          ))}
        </NavMenu>
        <MobileTopBar>
          <UpgradeWrapper to="/dashboard/upgrade">
            <UpgradeIcon>
              <HiCubeTransparent />
            </UpgradeIcon>
            <p>Upgrade Plan</p>
          </UpgradeWrapper>
          <ProfileButton onClick={toggleDropdown}>
            <ProfileIcon>
              <IoPersonOutline color={colors.white} />
            </ProfileIcon>
            <p>{user ? `${user?.firstName} ${user?.lastName}` : null}</p>
          </ProfileButton>

          {isDropdownOpen && (
            <DropdownMenu>
              <DropdownItem>Settings</DropdownItem>
              <DropdownItem>Logout</DropdownItem>
            </DropdownMenu>
          )}
        </MobileTopBar>
      </MobileSidebar>

      {/* Sidebar for large screens */}
      <Sidebar>
        <NavMenu>
          {navContent.map((item) => (
            <NavItem to={item.link} key={item.link}>
              {item.content}
            </NavItem>
          ))}
        </NavMenu>
      </Sidebar>

      <MainContent>
        <Content>{children}</Content>
      </MainContent>
    </DashboardContainer>
  );
};

export default DashboardLayout;

// Styled Components
const DashboardContainer = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  flex-direction: column;
`;

const TopBar = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.backgroundGrey};
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.1rem 1rem;
  z-index: 1002;

  @media (max-width: ${({ theme }) => theme.screens.minitab}) {
    display: none;
  }
`;

const TopBarMenu = styled.div`
  position: relative;
  display: flex;
  width: 38%;
  gap: 1rem;
`;

const ProfileButton = styled.button`
  display: flex;
  width: 100%;
  border: none;
  align-items: center;
  gap: 0.7rem;
  padding: 0.6rem 0;
  cursor: pointer;
  background-color: transparent;

  @media (max-width: ${({ theme }) => theme.screens.minitab}) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.deeperBackgroundGrey};
  }
`;

const ProfileIcon = styled.div`
  background-color: ${({ theme }) => theme.colors.black};
  border: none;
  display: flex;
  width: 2rem;
  height: 2rem;
  border-radius: 0.25rem;
  color: ${({ theme }) => theme.colors.white};
  align-items: center;
  justify-content: center;
`;

const UpgradeWrapper = styled(Link)`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 0.7rem;
  padding: 0.6rem 0;
  text-decoration: none !important;

  @media (max-width: ${({ theme }) => theme.screens.minitab}) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.deeperBackgroundGrey};
  }
`;

const UpgradeIcon = styled.div`
  display: flex;
  width: 1.7rem;
  height: 1.7rem;
  border: 0.5px solid ${({ theme }) => theme.colors.deeperBackgroundGrey};
  border-radius: 0.25rem;
  align-items: center;
  justify-content: center;
`;

const DropdownMenu = styled.div`
  position: absolute;
  right: 0;
  top: 3.5rem;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  z-index: 1003;
  width: 300px !important;

  @media (max-width: ${({ theme }) => theme.screens.minitab}) {
    bottom: 5rem !important;
    top: auto;
    right: 1rem;
    width: 91% !important;
    height: fit-content;
  }
`;

const DropdownItem = styled.div`
  padding: 0.2rem 0.5rem;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.textBody};

  &:hover {
    background-color: ${({ theme }) => theme.colors.deeperBackgroundGrey};
  }
`;

const Sidebar = styled.aside`
  width: 200px;
  background-color: ${({ theme }) => theme.colors.backgroundGrey};
  display: flex;
  flex-direction: column;
  padding: 4rem 1rem 1rem;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;

  @media (max-width: ${({ theme }) => theme.screens.minitab}) {
    display: none;
  }
`;

const MobileNavbar = styled.div`
  width: 100%;
  height: 3rem;
  box-sizing: border-box;
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.backgroundGrey};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  z-index: 1001;

  @media (min-width: ${({ theme }) => theme.screens.minitab}) {
    display: none;
  }
`;

const MobileSidebar = styled.aside<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.backgroundGrey};
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  flex-direction: column;
  padding: 4rem 1rem 1rem;
  z-index: 1000;

  @media (min-width: ${({ theme }) => theme.screens.minitab}) {
    display: none;
  }
`;

const MobileTopBar = styled.div`
  margin-top: auto;
  padding: 0.7rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.colors.backgroundGrey};
  gap: 1rem;
`;

const Logo = styled.div`
  h2,
  p {
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.textBody};
    margin-bottom: 0;
  }
`;

const NavMenu = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`;

const NavItem = styled(NavLink)`
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors.textBody};
  text-decoration: none;
  padding: 0.3rem;
  border-radius: 4px;

  &.active {
    background-color: ${({ theme }) => theme.colors.deeperBackgroundGrey};
    color: ${({ theme }) => theme.colors.textBody};
    font-weight: 500;
    text-decoration: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.deeperBackgroundGrey};
    text-decoration: none;
  }
`;

const HamburgerMenu = styled.div`
  font-size: 1.5rem;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.textBody};
`;

const MainContent = styled.main`
  margin-left: 200px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  @media (max-width: ${({ theme }) => theme.screens.minitab}) {
    margin-left: 0;
  }
`;

const Content = styled.section`
  p {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.textBody};
  }
`;
