import { useState } from "react";
import axios from "axios";

interface AIContentResponse {
  content: string;
  usage: {
    promptTokens: number;
    completionTokens: number;
    totalTokens: number;
  };
}

interface UseAIContentProps {
  apiKey: string;
  endpoint: string;
}

const useAIContent = ({ apiKey, endpoint }: UseAIContentProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<AIContentResponse | null>(null);

  const generateContent = async (
    prompt: string,
    options: {
      model?: string;
      temperature?: number;
      maxTokens?: number;
      topP?: number;
      frequencyPenalty?: number;
      presencePenalty?: number;
    } = {}
  ) => {
    if (!prompt) {
      setError("Prompt is required.");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        endpoint,
        {
          model: options.model || "text-davinci-003", // Default model
          prompt,
          temperature: options.temperature || 0.7,
          max_tokens: options.maxTokens || 150,
          top_p: options.topP || 1,
          frequency_penalty: options.frequencyPenalty || 0,
          presence_penalty: options.presencePenalty || 0,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
        }
      );

      setData({
        content: response.data.choices[0].text.trim(),
        usage: response.data.usage,
      });
    } catch (err: any) {
      setError(err.response?.data?.error?.message || err.message);
    } finally {
      setLoading(false);
    }
  };

  return { generateContent, loading, error, data };
};

export default useAIContent;
