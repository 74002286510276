import React from "react";
import styled from "styled-components";
import LogoutButton from "../../../features/auth/LogoutButton";
import { useAppSelector } from "../../../api/hooks";
import { selectAuth } from "../../../features/auth/authSlice";

const DashboardOverview = () => {
  const { user, token } = useAppSelector(selectAuth);

  return (
    <Container>
      <Header>
        <h1>Dashboard</h1>
        <p>
          Welcome to your personalized dashboard, {user?.firstName}! Below
          you'll find details about your account and available actions.
        </p>
      </Header>

      <Section>
        <h2>Your Details</h2>
        <DetailsList>
          <li>
            <strong>Name:</strong> {user?.firstName} {user?.lastName}
          </li>
          <li>
            <strong>Email:</strong> {user?.email}
          </li>
          <li>
            <strong>Token:</strong> <Code>token</Code>
          </li>
        </DetailsList>
      </Section>

      <Section>
        <h2>Actions</h2>
        <LogoutButton />
      </Section>

      <Footer>
        <p>
          Need help? Visit our{" "}
          <SupportLink href="/support">Support Center</SupportLink>.
        </p>
      </Footer>
    </Container>
  );
};

export default DashboardOverview;

// Styled Components

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 1rem;
`;

const Header = styled.header`
  margin-bottom: 2rem;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 1rem;
`;

const Section = styled.section`
  margin-bottom: 2rem;
`;

const DetailsList = styled.ul`
  list-style: none;
  padding: 0;
  line-height: 1.8rem;
  color: #333;

  li {
    strong {
      font-weight: bold;
    }
  }
`;

const Code = styled.code`
  background-color: #f4f4f4;
  padding: 0.2rem;
  border-radius: 4px;
`;

const Footer = styled.footer`
  border-top: 1px solid #e0e0e0;
  padding-top: 1rem;
  margin-top: 2rem;
  font-size: 0.9rem;
  color: #777;
`;

const SupportLink = styled.a`
  color: #007bff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
