import React from "react";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "../api/hooks";
import { RootState } from "../api/store";
import DashboardLayout from "../layouts/DsashboardLayout";

interface ProtectedRouteProps {
  element: React.ReactNode; // The element to render if authenticated
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
  const isAuthenticated = useAppSelector(
    (state: RootState) => !!state.auth.token
  );

  // Wrap the element inside DashboardLayout if authenticated
  if (isAuthenticated) {
    return <DashboardLayout>{element}</DashboardLayout>;
  }

  // If not authenticated, redirect to login page
  return <Navigate to="/login" replace />;
};

export default ProtectedRoute;
