import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";

const url = process.env.REACT_APP_API_URI as string;

export const productApi = createApi({
  reducerPath: "productApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${url}/products`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    // Mutation to create a product
    createProduct: builder.mutation({
      query: (productData) => ({
        url: "/create-product",
        method: "POST",
        body: productData,
      }),
    }),

    // Query to get all products for a user and business
    getAllProducts: builder.query({
      query: ({ business }) => ({
        url: `${business}/get-all-products`,
      }),
    }),

    // Query to get a product by ID
    getProductById: builder.query({
      query: (id) => ({
        url: `/products/${id}`,
      }),
    }),

    // Mutation to edit a product by ID
    editProductById: builder.mutation({
      query: ({ id, updateData }) => ({
        url: `/products/${id}/edit`,
        method: "PATCH",
        body: updateData,
      }),
    }),
  }),
});

export const {
  useCreateProductMutation,
  useGetAllProductsQuery,
  useGetProductByIdQuery,
  useEditProductByIdMutation,
} = productApi;
