import AIContentGenerator from "../components/AIContentGenerator";
import DashboardOverview from "../components/Dashboard/DashboardOverview";
import Questionnaire from "../components/Dashboard/Questionnaire";
import Recommendations from "../components/Dashboard/Questionnaire/Recommendations";
import ProtectedRoute from "../components/ProtectedRoute";
import CheckEmail from "../features/auth/CheckEmail";
import Login from "../features/auth/Login";
import Register from "../features/auth/Register";
import VerifyEmail from "../features/auth/VerifyEmail";
import BusinessOverview from "../features/business/BusinessOverview";
import CreateBusiness from "../features/business/CreateBusiness";
import EditBusiness from "../features/business/EditBusiness";
import AllFunnels from "../features/funnels/AllFunnels";
import ChooseProductForFunnel from "../features/funnels/ChooseProductForFunnel";
import FunnelBuilder from "../features/funnels/FunnelBuilder";
import ViewFunnel from "../features/funnels/ViewFunnel";
import CreateProduct from "../features/products/CreateProduct";
import EditProduct from "../features/products/EditProduct";
import GetAllProducts from "../features/products/GetAllProducts";
import GetOneProduct from "../features/products/GetOneProduct";
import Dashboard from "../pages/Dashboard";

export const routes = [
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/check-email",
    element: <CheckEmail />,
  },
  {
    path: "/verify-email",
    element: <VerifyEmail />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/",
    element: <AIContentGenerator />,
  },
  {
    path: "/dashboard",
    element: <ProtectedRoute element={<Dashboard />} />,
    children: [
      {
        path: "home",
        element: <Questionnaire />,
      },
      {
        path: "home/recommendations",
        element: <Recommendations />,
      },
      {
        path: "home/overview",
        element: <DashboardOverview />,
      },
      {
        path: "businesses",
        element: <BusinessOverview />,
      },
      {
        path: "businesses/create-business",
        element: <CreateBusiness />,
      },
      {
        path: "businesses/:businessId/edit-business",
        element: <EditBusiness />,
      },
      {
        path: "products",
        element: <GetAllProducts />,
      },
      {
        path: "products/:productId",
        element: <GetOneProduct />,
      },
      {
        path: "products/create-product",
        element: <CreateProduct />,
      },
      {
        path: "products/:id/edit-product",
        element: <EditProduct />,
      },
      {
        path: "funnels",
        element: <AllFunnels />,
      },
      {
        path: "funnels/:funnelId",
        element: <ViewFunnel />,
      },
      {
        path: "funnels/choose-product",
        element: <ChooseProductForFunnel />,
      },
      {
        path: "funnels/products/:productId/create",
        element: <FunnelBuilder />,
      },
    ],
  },
];
