// Textarea.tsx
import React from "react";
import { styled } from "styled-components";

type TextareaProps = {
  name: string;
  placeholder?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
};

const TextArea: React.FC<TextareaProps> = ({
  name,
  placeholder,
  value,
  onChange,
}) => {
  return (
    <StyledTextarea
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  );
};

export default TextArea;

const StyledTextarea = styled.textarea`
  width: 100%;
  padding: 0.8rem;
  font-size: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 6px;
  outline: none;
  resize: vertical;
  transition: border-color 0.3s;

  &:focus {
    border-color: ${({ theme }) => theme.colors.green};
  }
`;
