import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";

// Base URL
const url = process.env.REACT_APP_API_URI as string;

export const businessApi = createApi({
  reducerPath: "businessApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${url}/business`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    // Create business mutation
    createBusiness: builder.mutation({
      query: (businessData) => ({
        url: "create-business",
        method: "POST",
        body: businessData,
      }),
    }),

    // Get business query
    getBusiness: builder.query({
      query: (userId) => `get-business/${userId}`,
    }),

    // Edit business mutation
    editBusiness: builder.mutation({
      query: ({ businessData }) => ({
        url: `edit-business`,
        method: "PATCH",
        body: businessData,
      }),
    }),
  }),
});

export const {
  useCreateBusinessMutation,
  useGetBusinessQuery,
  useEditBusinessMutation,
} = businessApi;
