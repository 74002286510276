import React from "react";
import { styled } from "styled-components";
import Button from "../../components/common/Button";
import { useNavigate } from "react-router-dom";

const AllFunnels = () => {
    const navigate = useNavigate()

    const handleNavigate = () => {
        navigate("choose-product")
    }

  return (
    <Container>
      <Wrapper>
        <TitleWrapper>
          <h2>All Funnels</h2>
        </TitleWrapper>
        <Button onClick={handleNavigate}>Create a new funnel</Button>
      </Wrapper>
    </Container>
  );
};

export default AllFunnels;

const Container = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  min-height: 100vh;
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 1rem 2rem;
`;

const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;
