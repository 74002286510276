import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useVerifyEmailMutation } from "../api";
import { useAppDispatch } from "../../api/hooks";
import { setUser } from "./authSlice";
import styled from "styled-components";
import { CustomError } from "../../types";

const VerifyEmail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [verifyEmail, { isLoading, isSuccess, isError, error, data }] =
    useVerifyEmailMutation();
  const [message, setMessage] = useState<string | null>(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");

    if (!token) {
      setMessage("No verification token provided.");
      return;
    }

    verifyEmail(token);
  }, [location.search, verifyEmail]);

  useEffect(() => {
    if (isSuccess && data) {
      dispatch(
        setUser({
          firstName: data.user.firstName,
          lastName: data.user.lastName,
          email: data.user.email,
          roles: data.user.roles || [],
          businessId: data.user.businessId,
          isActive: data.user.isActive,
          token: data.token,
          id: data.user.id,
        })
      );
      navigate("/dashboard/home");
      setMessage("Email verified successfully and logged in!");
    }

    if (isError) {
      const customError = error as CustomError;
      setMessage(customError?.data?.error || "Verification failed.");
    }
  }, [isSuccess, isError, data, error, dispatch]);

  const handleNavigateToLogin = () => {
    navigate("/login");
  };

  return (
    <Container>
      <Wrapper>
        <FormWrapper>
          {isLoading ? (
            <>
              <Title>Verifying Email...</Title>
              <Text>Please wait while we verify your email...</Text>
            </>
          ) : (
            <>
              <Title>
                {isSuccess
                  ? "Email Verified Successfully"
                  : "Email Verification"}
              </Title>
              <Text>{message}</Text>
              {isSuccess && (
                <Button onClick={handleNavigateToLogin} disabled={isLoading}>
                  Go to Login Page
                </Button>
              )}
            </>
          )}
        </FormWrapper>
      </Wrapper>
    </Container>
  );
};

export default VerifyEmail;

const Container = styled.div`
  display: flex;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.backgroundGrey};
  min-height: 100vh;
`;

const Wrapper = styled.div`
  display: flex;
  width: 90%;
  max-width: 400px;
  margin: 2rem auto;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  height: 100vh - 4rem;
`;

const FormWrapper = styled.div`
  width: 100%;
  padding: 2rem;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.textBody};
  margin-bottom: 1.5rem;
`;

const Text = styled.p`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.textBody};
  margin-bottom: 1rem;
`;

const Button = styled.button`
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: ${({ theme }) => theme.colors.green};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;

  &:disabled {
    background-color: ${({ theme }) => theme.colors.disabled};
    cursor: not-allowed;
  }
`;
